import {
  ActorType,
  CargoType,
  SupportAdminLevel,
  UserLevel
} from "@/scripts/cld.api";
import { UserInfo } from "@/scripts/types";
import Auth from "@/scripts/auth";
import { cargoTypes } from "@/scripts/misc/enumLists";
import { cargoTypeNameTag, cargoTypeIcon } from "@/scripts/misc/enumNames";

export interface menu {
  text?: string;
  to?: string;
  icon?: string;
  minUserLevel?: UserLevel;
  onlyForCargoTypes?: CargoType[];
  minSupportAdminLevel?: number;
  untranslated?: boolean;
  invisible?: boolean;
  items?: menu[];
  onlyForActors?: number[];
}

export const pageForbiddenReason = (
  userInfo: UserInfo,
  to: string
): string | undefined => {
  const menuItems = menuBase(userInfo);
  const match = matchingItem(menuItems, to);
  if (match === undefined) {
    return `This page is not visible for your actor type. You have actor type ${userInfo.actorType}.`;
  }
  if (!visibleForUserLevel(match, userInfo.userLevel!)) {
    return `This page is only visible for user levels ${match.minUserLevel} and above. You have userLevel ${userInfo.userLevel}.`;
  }
  if (!visibleForCargoTypes(match, userInfo.actorCargoTypes)) {
    return `This page is only visible for cargo types ${match.onlyForCargoTypes}. You have cargo types ${userInfo.actorCargoTypes}.`;
  }
  if (Auth.browsingSupportAdmin() && userInfo.supportAdminLevel < 1) {
    return `This page is part of c-admin and therefore requires a support admin level of at least ${1}. You have support admin level ${
      userInfo.supportAdminLevel
    }.`;
  }
  if (!visibleForSupportAdminLevel(match, userInfo.supportAdminLevel)) {
    return `This page is a unusually dangerous and therefore requires a support admin level of at least ${match.minSupportAdminLevel}. You have support admin level ${userInfo.supportAdminLevel}.`;
  }
  if (!visibleForCargoLoadingActors(match, userInfo.actorId!)) {
    return `This page is only visible for actors with slotbooking.`;
  }
  return undefined;
};

export default (userInfo: UserInfo): menu[] => {
  let menuItems = menuBase(userInfo);
  menuItems = visibleItems(
    menuItems,
    userInfo.userLevel!,
    userInfo.actorCargoTypes,
    userInfo.supportAdminLevel,
    userInfo.actorId!
  );
  for (const item of menuItems) {
    item.items = visibleItems(
      item.items !== undefined ? item.items : [],
      userInfo.userLevel!,
      userInfo.actorCargoTypes,
      userInfo.supportAdminLevel,
      userInfo.actorId!
    );
  }
  return menuItems;
};

const matchingItem = (items: menu[], to: string): menu | undefined => {
  for (const item of items) {
    if (matchingRoute(to, item.to)) {
      return item;
    } else if (item.items !== undefined) {
      const match = matchingItem(item.items, to);
      if (match !== undefined) {
        return match;
      }
    }
  }
  return undefined;
};

const matchingRoute = (
  requestedRoute: string,
  systemRoute: string | undefined
): boolean => {
  if (systemRoute === undefined) {
    return false;
  }
  const [reqPath, reqQS] = destructuredRoute(requestedRoute);
  const [sysPath, sysQS] = destructuredRoute(systemRoute);
  if (reqPath === sysPath && reqQS.startsWith(sysQS)) {
    return true;
  }
  return false;
};

const destructuredRoute = (route: string): [string, string] => {
  let qs = "";
  const qsStart = route.indexOf("?");
  if (qsStart !== -1) {
    qs = route.substring(qsStart);
    route = route.substr(0, qsStart);
  }
  if (route.endsWith("/")) {
    route = route.substr(0, route.length - 1);
  }
  return [route.toLowerCase(), qs.toLowerCase()];
};

const menuBase = (userInfo: UserInfo): menu[] => {
  let menu: menu[];

  if (Auth.browsingSupportAdmin()) {
    menu = supportAdminMenu;
  } else if (Auth.browsingMobile()) {
    menu = mobileMenu;
  } else if (userInfo.userLevel === UserLevel.Integration) {
    return [];
  } else if (userInfo.actorType === ActorType.Client) {
    menu = clientMenu;
  } else if (userInfo.actorType === ActorType.Supplier) {
    menu = supplierMenu;
  } else if (userInfo.actorType === ActorType.Customer) {
    menu = customerMenu;
  } else if (userInfo.actorType === ActorType.Terminal) {
    menu = terminalMenu;
  } else {
    throw new Error(`ActorType ${userInfo.actorType} has no menu`);
  }
  return menu.map(m => ({ ...m, items: m.items }));
};

const visibleItems = (
  items: menu[],
  userLevel: UserLevel,
  cargoTypes: CargoType[],
  supportAdminLevel: number,
  actorId: number
) => {
  items = items.filter(item => !item.invisible);
  items = items.filter(item => visibleForUserLevel(item, userLevel));
  items = items.filter(item => visibleForCargoTypes(item, cargoTypes));
  items = items.filter(item =>
    visibleForSupportAdminLevel(item, supportAdminLevel)
  );
  items = items.filter(item => visibleForCargoLoadingActors(item, actorId));
  return items;
};

const visibleForUserLevel = (item: menu, userLevel: UserLevel): boolean => {
  const minUserLevel = item.minUserLevel || UserLevel.Viewer;
  return userLevel >= minUserLevel;
};

const visibleForCargoTypes = (item: menu, cargoTypes: CargoType[]): boolean => {
  return (
    item.onlyForCargoTypes === undefined ||
    item.onlyForCargoTypes.some(c => cargoTypes.includes(c))
  );
};

const visibleForSupportAdminLevel = (
  item: menu,
  supportAdminLevel: number
): boolean => {
  return (
    item.minSupportAdminLevel === undefined ||
    item.minSupportAdminLevel <= supportAdminLevel
  );
};
const visibleForCargoLoadingActors = (item: menu, actorId: number): boolean => {
  return (
    item.onlyForActors === undefined ||
    item.onlyForActors.some(a => a === actorId)
  );
};

/******************** Quick links  ********************/
const quickLinks: menu = {
  text: "Länkar",
  minSupportAdminLevel: 1,
  untranslated: true,
  items: [
    {
      text: "C-Admin",
      to: "/support-admin",
      icon: "mdi-chess-king",
      minSupportAdminLevel: 1,
      untranslated: true
    },
    {
      text: "Web",
      to: "/",
      icon: "mdi-monitor",
      untranslated: true
    },
    {
      text: "Mobile",
      to: "/mobile",
      icon: "mdi-cellphone",
      untranslated: true
    },
    {
      text: "AppOptics",
      to: "https://my.appoptics.com/s/public/883czgr6v",
      icon: "mdi-open-in-new",
      minSupportAdminLevel: 1,
      untranslated: true
    }
  ]
};

/********************** C-Admin **********************/
const supportAdminMenu: menu[] = [
  {
    text: "Utforskaren",
    to: "/support-admin/explorer",
    untranslated: true
  },
  {
    text: "Aktörslistan",
    to: "/support-admin/actors",
    untranslated: true
  },
  {
    text: "Avtalslistan",
    to: "/support-admin/agreements",
    untranslated: true
  },
  {
    text: "Mailviewer",
    to: "/support-admin/mailviewer",
    untranslated: true
  },
  {
    text: "Nyheter",
    to: "/support-admin/news",
    untranslated: true
  },
  {
    text: "Övrigt",
    untranslated: true,
    items: [
      {
        text: "Lägg till ort",
        to: "/support-admin/add-location",
        icon: "mdi-map-marker-plus",
        untranslated: true
      },
      {
        text: "Lägg till i upphandling",
        to: "/support-admin/add-to-procurements",
        icon: "mdi-plus-thick",
        untranslated: true
      },
      {
        text: "Aktörers dokument",
        to: "/support-admin/documents",
        icon: "mdi-file-document",
        untranslated: true
      },
      {
        text: "Avtalssignering",
        to: "/support-admin/actor-signing",
        icon: "mdi-file-sign",
        untranslated: true
      },
      {
        text: "QP-visualisering",
        to: "/support-admin/qp-vis",
        icon: "mdi-clipboard-list",
        untranslated: true
      },
      {
        text: "QP-viktning",
        to: "/support-admin/qp-weights",
        icon: "mdi-clipboard-list",
        untranslated: true
      }
    ]
  },
  {
    text: "Avancerat",
    untranslated: true,
    minSupportAdminLevel: 2,
    items: [
      {
        text: "Kraftfulla knappar",
        to: "/support-admin/power-buttons",
        icon: "mdi-weight-lifter",
        minSupportAdminLevel: 2,
        untranslated: true
      },
      {
        text: "Bakgrundsjobb",
        to: "/support-admin/background-tasks",
        icon: "mdi-update",
        minSupportAdminLevel: 2,
        untranslated: true
      },
      {
        text: "Indexuppdatering",
        to: "/support-admin/index-components",
        icon: "mdi-chart-line",
        minSupportAdminLevel: 2,
        untranslated: true
      },
      {
        text: "Generera fakturaunderlag",
        to: "/support-admin/invoicing",
        icon: "mdi-currency-usd",
        minSupportAdminLevel: 2,
        untranslated: true
      },
      {
        text: "Uppdatera avstånd",
        to: "/support-admin/distance",
        icon: "mdi-map-marker-distance",
        minSupportAdminLevel: 2,
        untranslated: true
      },
      {
        text: "Star Wars",
        to: "/support-admin/but-why",
        icon: "mdi-space-invaders",
        minSupportAdminLevel: 2,
        untranslated: true
      },
      {
        text: "MailTemplate",
        to: "/support-admin/mail-template",
        icon: "mdi-skull",
        minSupportAdminLevel: 3,
        untranslated: true
      },
      {
        text: "Playground",
        to: "/support-admin/playground",
        icon: "mdi-skull",
        minSupportAdminLevel: 3,
        untranslated: true
      }
    ]
  },
  {
    text: "Statistik",
    untranslated: true,
    items: [
      {
        text: "Megarapporten",
        to: "/support-admin/mega-report",
        icon: "mdi-chart-bell-curve",
        untranslated: true
      },
      {
        text: "Värmekarta lastning",
        to: "/support-admin/heat-map",
        icon: "mdi-chart-bell-curve",
        untranslated: true
      },
      {
        text: "Aktörer",
        to: "/support-admin/actors-statistics",
        icon: "mdi-account-multiple",
        untranslated: true
      },
      {
        text: "Fakturerade avgifter",
        to: "/support-admin/invoiced-fees-report",
        icon: "mdi-credit-card-clock",
        untranslated: true
      }
    ]
  },
  quickLinks,
  {
    //ALLOWED NON-MENU ITEMS FOR C-ADMIN,
    invisible: true,
    items: [
      {
        to: "/support-admin/edit-news"
      }
    ]
  }
];

/******************** Client ********************/
const clientMenu: menu[] = [
  {
    text: "ManageLoads",
    items: [
      {
        text: "LoadList",
        to: "/loads",
        icon: "mdi-format-list-bulleted"
      },
      {
        text: "FindLoad",
        to: "/search",
        icon: "mdi-magnify"
      },
      ...cargoTypes()
        .map(x => x.id as CargoType)
        .map(c => ({
          text: "CreateNewLoad",
          to: "/loads?action=1&ordertype=" + c,
          icon: cargoTypeIcon(c),
          minUserLevel: UserLevel.User,
          onlyForCargoTypes: [c]
        })),
      {
        text: "LoadingSlots",
        to: "/slots",
        icon: "mdi-calendar-check",
        onlyForActors: [51, 49]
      }
    ]
  },
  {
    text: "Procurements",
    items: [
      {
        text: "AgreementRoutes",
        to: "/agreement-routes",
        icon: "mdi-map-marker-distance"
      },
      {
        text: "CreateProcurement",
        to: "/create-procurement",
        icon: "mdi-text-box-plus",
        minUserLevel: UserLevel.Admin
      },
      ...cargoTypes()
        .map(x => x.id as CargoType)
        .map(c => ({
          text: cargoTypeNameTag(c),
          to: "/procurements?cargotype=" + c,
          icon: cargoTypeIcon(c),
          onlyForCargoTypes: [c],
          minUserLevel: UserLevel.Admin
        }))
    ]
  },
  {
    text: "Agreements",
    minUserLevel: UserLevel.User,
    items: [
      ...cargoTypes()
        .map(x => x.id as CargoType)
        .map(c => ({
          text: cargoTypeNameTag(c),
          to: "/agreements?cargotype=" + c,
          icon: cargoTypeIcon(c),
          onlyForCargoTypes: [c]
        })),
      {
        text: "Timber Direct Export",
        untranslated: true,
        to: "/setra-export",
        icon: "mdi-microsoft-excel",
        onlyForActors: [311, 49]
      }
    ]
  },
  {
    text: "Monitoring",
    minUserLevel: UserLevel.User,
    items: [
      {
        text: "LoadStatistics",
        to: "/load-statistics",
        icon: "mdi-chart-bar"
      },
      {
        text: "AgreementStatistics",
        to: "/agreement-statistics",
        icon: "mdi-text-box-multiple"
      },
      {
        text: "QualityPoints",
        to: "/quality-points",
        icon: "mdi-clipboard-list"
      },
      {
        text: "ClientMonitoring",
        to: "/client-monitoring",
        icon: "mdi-finance"
      },
      {
        text: "ClientRouteCost",
        to: "/cost-report",
        icon: "mdi-currency-usd"
      },
      {
        text: "CostForecast",
        to: "/cost-forecast",
        icon: "mdi-poll"
      },
      {
        text: "ProcurementAnalytics",
        to: "/procurement-analytics",
        icon: "mdi-poll"
      },
      {
        text: "InvoiceAudit",
        to: "/invoice-report",
        icon: "mdi-credit-card-search-outline"
      },
      {
        text: "Deviations",
        to: "/deviation-report",
        icon: "mdi-sticker-alert-outline"
      },
      {
        text: "TransportDistances",
        to: "/distance-report",
        icon: "mdi-map-marker-distance",
        onlyForCargoTypes: [CargoType.Road, CargoType.MachineTransport]
      },
      {
        text: "Sustainability",
        to: "/surveys",
        icon: "mdi-leaf"
      }
    ]
  },
  {
    text: "Actors",
    items: [
      {
        text: "MyActor",
        to: "/my-actor",
        icon: "mdi-account-multiple"
      },
      {
        text: "Suppliers",
        to: "/actors?actorType=" + ActorType.Supplier,
        icon: "mdi-account-multiple"
      },
      {
        text: "Customers",
        to: "/actors?actorType=" + ActorType.Customer,
        icon: "mdi-account-multiple"
      },
      {
        text: "WeightningQualitypoint",
        to: "/quality-points-weights",
        icon: "mdi-clipboard-list",
        minUserLevel: UserLevel.Admin
      },
      {
        text: "BusinessQualitypoints",
        to: "/subjective-quality-points",
        icon: "mdi-clipboard-account",
        minUserLevel: UserLevel.Admin
      },
      {
        text: "Documents",
        to: "/documents",
        icon: "mdi-file-document",
        minUserLevel: UserLevel.User
      }
    ]
  },
  {
    text: "MenuItemAdmin",
    minUserLevel: UserLevel.SuperAdmin,
    items: [
      {
        text: "AdminActor",
        to: "/administrate-actor",
        icon: "mdi-account-multiple",
        minUserLevel: UserLevel.SuperAdmin
      },
      {
        text: "AdministrateUsers",
        to: "/administrate-users",
        icon: "mdi-account-multiple",
        minUserLevel: UserLevel.SuperAdmin
      },
      {
        text: "PublishRules",
        to: "/publish-rules",
        icon: "mdi-format-list-numbered",
        minUserLevel: UserLevel.SuperAdmin
      },
      {
        text: "ExcludedSuppliers",
        to: "/excluded-actors",
        icon: "mdi-account-cancel",
        minUserLevel: UserLevel.SuperAdmin
      }
    ]
  },
  {
    text: "About",
    items: [
      {
        text: "CurrentIndexes",
        to: "/indexes",
        icon: "mdi-chart-line"
      },
      {
        text: "AboutLasset",
        to: "/about",
        icon: "mdi-information"
      },
      {
        text: "News",
        to: "/news",
        icon: "mdi-newspaper-variant-outline"
      },
      {
        text: "ReleaseNotes",
        to: "/documentation/docs_release_notes/_site/release_notes/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "ServiceSpec",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/service_specification/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "PriceList",
        to: "/price-list",
        icon: "mdi-currency-usd"
      },
      {
        text: "CodeOfConduct",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/code_of_conduct/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsIt",
        to: "/i/ITServices_USERLANG.pdf",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsCloud",
        to: "/i/CloudServices_USERLANG.pdf",
        icon: "mdi-file-document"
      }
    ]
  },
  {
    text: "Support",
    items: [
      {
        text: "SupportForm",
        to: "/support-form",
        icon: "mdi-face-agent"
      },
      {
        text: "FAQ",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/faq/index.html",
        icon: "mdi-frequently-asked-questions"
      },
      {
        text: "UserManual",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/index.html",
        icon: "mdi-help-box"
      }
    ]
  },
  quickLinks,
  {
    //ALLOWED NON-MENU ITEMS FOR CLIENTS,
    invisible: true,
    items: [
      {
        to: "/"
      },
      {
        to: "/my-settings"
      },
      {
        to: "/procurement-overview",
        minUserLevel: UserLevel.Admin
      },
      {
        to: "/messages",
        minUserLevel: UserLevel.User
      },
      {
        to: "/user-mutation",
        minSupportAdminLevel: SupportAdminLevel.MegaUser
      }
    ]
  }
];

/******************** Supplier ********************/
const supplierMenu: menu[] = [
  {
    text: "ManageLoads",
    items: [
      {
        text: "LoadList",
        to: "/loads",
        icon: "mdi-format-list-bulleted"
      },
      {
        text: "FindLoad",
        to: "/search",
        icon: "mdi-magnify"
      },
      {
        text: "LoadingSlots",
        to: "/slots",
        icon: "mdi-calendar-check",
        onlyForActors: [982, 48]
      }
    ]
  },
  {
    text: "Procurements",
    minUserLevel: UserLevel.Admin,
    items: cargoTypes()
      .map(x => x.id as CargoType)
      .map(c => ({
        text: cargoTypeNameTag(c),
        to: "/procurements?cargotype=" + c,
        icon: cargoTypeIcon(c),
        onlyForCargoTypes: [c]
      }))
  },
  {
    text: "Agreements",
    minUserLevel: UserLevel.User,
    items: [
      ...cargoTypes()
        .map(x => x.id as CargoType)
        .map(c => ({
          text: cargoTypeNameTag(c),
          to: "/agreements?cargotype=" + c,
          icon: cargoTypeIcon(c),
          onlyForCargoTypes: [c]
        })),
      {
        text: "TermsOfAgreement",
        to: "/documents",
        icon: "mdi-file-sign",
        minUserLevel: UserLevel.User
      },
      {
        text: "InvoicingInformation",
        to: "/documents",
        icon: "mdi-currency-usd",
        minUserLevel: UserLevel.User
      }
    ]
  },
  {
    text: "Monitoring",
    minUserLevel: UserLevel.User,
    items: [
      {
        text: "LoadStatistics",
        to: "/load-statistics",
        icon: "mdi-finance"
      },
      {
        text: "AgreementStatistics",
        to: "/agreement-statistics",
        icon: "mdi-text-box-multiple"
      },
      {
        text: "QualityPoints",
        to: "/quality-points",
        icon: "mdi-clipboard-list"
      },
      {
        text: "InvoiceAudit",
        to: "/invoice-report",
        icon: "mdi-credit-card-search-outline"
      },
      {
        text: "TransportDistances",
        to: "/distance-report",
        icon: "mdi-map-marker-distance",
        onlyForCargoTypes: [CargoType.Road, CargoType.MachineTransport]
      },
      {
        text: "Survey_SustainabilitySurvey",
        to: "/surveys",
        icon: "mdi-leaf",
        minUserLevel: UserLevel.Admin
      }
    ]
  },
  {
    text: "Actors",
    items: [
      {
        text: "MyActor",
        to: "/my-actor",
        icon: "mdi-account-multiple"
      },
      {
        text: "Clients",
        to: "/actors?actorType=" + ActorType.Client,
        icon: "mdi-account-multiple"
      },
      {
        text: "WeightningQualitypoint",
        to: "/quality-points-weights",
        icon: "mdi-clipboard-list",
        minUserLevel: UserLevel.User
      },
      {
        text: "BusinessQualitypoints",
        to: "/subjective-quality-points",
        icon: "mdi-clipboard-account",
        minUserLevel: UserLevel.User
      }
    ]
  },
  {
    text: "MenuItemAdmin",
    minUserLevel: UserLevel.SuperAdmin,
    items: [
      {
        text: "AdminActor",
        to: "/administrate-actor",
        icon: "mdi-account-multiple",
        minUserLevel: UserLevel.SuperAdmin
      },
      {
        text: "AdministrateUsers",
        to: "/administrate-users",
        icon: "mdi-account-multiple",
        minUserLevel: UserLevel.SuperAdmin
      },
      {
        text: "ExcludedClients",
        to: "/excluded-actors",
        icon: "mdi-account-cancel",
        minUserLevel: UserLevel.SuperAdmin
      }
    ]
  },
  {
    text: "About",
    items: [
      {
        text: "CurrentIndexes",
        to: "/indexes",
        icon: "mdi-chart-line"
      },
      {
        text: "AboutLasset",
        to: "/about",
        icon: "mdi-information"
      },
      {
        text: "News",
        to: "/news",
        icon: "mdi-newspaper-variant-outline"
      },
      {
        text: "ReleaseNotes",
        to: "/documentation/docs_release_notes/_site/release_notes/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "ServiceSpec",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/service_specification/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "PriceList",
        to: "/price-list",
        icon: "mdi-currency-usd"
      },
      {
        text: "CodeOfConduct",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/code_of_conduct/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsIt",
        to: "/i/ITServices_USERLANG.pdf",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsCloud",
        to: "/i/CloudServices_USERLANG.pdf",
        icon: "mdi-file-document"
      }
    ]
  },
  {
    text: "Support",
    items: [
      {
        text: "SupportForm",
        to: "/support-form",
        icon: "mdi-face-agent"
      },
      {
        text: "FAQ",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/faq/index.html",
        icon: "mdi-frequently-asked-questions"
      },
      {
        text: "UserManual",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/index.html",
        icon: "mdi-help-box"
      }
    ]
  },
  quickLinks,
  {
    //ALLOWED NON-MENU ITEMS FOR SUPPLIERS,
    invisible: true,
    items: [
      {
        to: "/"
      },
      {
        to: "/my-settings"
      },
      {
        to: "/survey",
        minUserLevel: UserLevel.Admin
      },
      {
        to: "/messages",
        minUserLevel: UserLevel.User
      },
      {
        to: "/user-mutation",
        minSupportAdminLevel: SupportAdminLevel.MegaUser
      }
    ]
  }
];

/******************** Customer ********************/
const customerMenu: menu[] = [
  {
    text: "ManageLoads",
    items: [
      {
        text: "LoadList",
        to: "/loads",
        icon: "mdi-format-list-bulleted"
      },
      {
        text: "FindLoad",
        to: "/search",
        icon: "mdi-magnify"
      }
    ]
  },
  {
    text: "MenuItemAdmin",
    minUserLevel: UserLevel.SuperAdmin,
    items: [
      {
        text: "AdministrateUsers",
        to: "/administrate-users",
        icon: "mdi-account-multiple",
        minUserLevel: UserLevel.SuperAdmin
      }
    ]
  },
  {
    text: "About",
    items: [
      {
        text: "CurrentIndexes",
        to: "/indexes",
        icon: "mdi-chart-line"
      },
      {
        text: "AboutLasset",
        to: "/about",
        icon: "mdi-information"
      },
      {
        text: "News",
        to: "/news",
        icon: "mdi-newspaper-variant-outline"
      },
      {
        text: "ReleaseNotes",
        to: "/documentation/docs_release_notes/_site/release_notes/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "ServiceSpec",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/service_specification/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "PriceList",
        to: "/price-list",
        icon: "mdi-currency-usd"
      },
      {
        text: "CodeOfConduct",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/code_of_conduct/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsIt",
        to: "/i/ITServices_USERLANG.pdf",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsCloud",
        to: "/i/CloudServices_USERLANG.pdf",
        icon: "mdi-file-document"
      }
    ]
  },
  {
    text: "Support",
    items: [
      {
        text: "SupportForm",
        to: "/support-form",
        icon: "mdi-face-agent"
      },
      {
        text: "FAQ",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/faq/index.html",
        icon: "mdi-frequently-asked-questions"
      },
      {
        text: "UserManual",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/index.html",
        icon: "mdi-help-box"
      }
    ]
  },
  quickLinks,
  {
    //ALLOWED NON-MENU ITEMS FOR CUSTOMERS
    invisible: true,
    items: [
      {
        to: "/"
      },
      {
        to: "/my-settings"
      },
      {
        to: "/user-mutation",
        minSupportAdminLevel: SupportAdminLevel.MegaUser
      }
    ]
  }
];

/******************** Terminal ********************/
const terminalMenu: menu[] = [
  {
    text: "ManageLoads",
    items: [
      {
        text: "LoadList",
        to: "/loads",
        icon: "mdi-format-list-bulleted"
      },
      {
        text: "FindLoad",
        to: "/search",
        icon: "mdi-magnify"
      }
    ]
  },
  {
    text: "MenuItemAdmin",
    minUserLevel: UserLevel.SuperAdmin,
    items: [
      {
        text: "AdministrateUsers",
        to: "/administrate-users",
        icon: "mdi-account-multiple",
        minUserLevel: UserLevel.SuperAdmin
      }
    ]
  },
  {
    text: "About",
    items: [
      {
        text: "CurrentIndexes",
        to: "/indexes",
        icon: "mdi-chart-line"
      },
      {
        text: "AboutLasset",
        to: "/about",
        icon: "mdi-information"
      },
      {
        text: "News",
        to: "/news",
        icon: "mdi-newspaper-variant-outline"
      },
      {
        text: "ReleaseNotes",
        to: "/documentation/docs_release_notes/_site/release_notes/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "ServiceSpec",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/service_specification/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "PriceList",
        to: "/price-list",
        icon: "mdi-currency-usd"
      },
      {
        text: "CodeOfConduct",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/code_of_conduct/index.html",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsIt",
        to: "/i/ITServices_USERLANG.pdf",
        icon: "mdi-file-document"
      },
      {
        text: "GenProvisionsCloud",
        to: "/i/CloudServices_USERLANG.pdf",
        icon: "mdi-file-document"
      }
    ]
  },
  {
    text: "Support",
    items: [
      {
        text: "SupportForm",
        to: "/support-form",
        icon: "mdi-face-agent"
      },
      {
        text: "FAQ",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/faq/index.html",
        icon: "mdi-frequently-asked-questions"
      },
      {
        text: "UserManual",
        to:
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/index.html",
        icon: "mdi-help-box"
      }
    ]
  },
  quickLinks,
  {
    //ALLOWED NON-MENU ITEMS FOR TERMINAL
    invisible: true,
    items: [
      {
        to: "/"
      },
      {
        to: "/my-settings"
      },
      {
        to: "/user-mutation",
        minSupportAdminLevel: SupportAdminLevel.MegaUser
      }
    ]
  }
];
/******************** Mobile ********************/
const mobileMenu: menu[] = [
  {
    text: "LoadList",
    to: "/mobile"
  },
  {
    text: "Support",
    to: "/mobile/support-form"
  },
  {
    text: "AboutLasset",
    to: "/mobile/about"
  },
  ...quickLinks.items!,
  {
    //ALLOWED NON-MENU ITEMS FOR C-LOAD MOBILE,
    invisible: true,
    items: [
      {
        to: "/mobile/slots"
      }
    ]
  }
];

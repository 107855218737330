






























































































































































































































































import Vue from "vue";
import {
  ActorType,
  CargoType,
  LoadListContainerDto,
  LoadListDto,
  LoadSearchCriteriaDto,
  UserConfigurationType
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import loadListHeaders from "@/scripts/misc/loadListHeaders";
import { LoadListTab, TableHeader, TableOptions } from "@/scripts/types";
import { actions, gets } from "@/scripts/store/constants";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import LoadDots from "@/components/web/loads/shared/list/LoadDots.vue";
import LoadInfoIcons from "@/components/web/loads/shared/list/LoadInfoIcons.vue";
import {
  deviationCauseName,
  loadStatusName,
  otherPriceCauseName,
  truckTypeName
} from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    LoadDots,
    LoadInfoIcons
  },
  props: {
    loading: Boolean,
    loadListContainer: LoadListContainerDto,
    tableOptions: Object as () => TableOptions,
    selectedTab: LoadListTab,
    checked: Array as () => number[]
  },
  watch: {
    loadIds: {
      handler() {
        this.fetchLoadPermissions();
        this.fetchLoadLocks();
      },
      immediate: true
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 285);
    },
    loadIds(): number[] {
      return this.loadListContainer.loads!.map(l => l.id);
    },
    searchCriteria(): LoadSearchCriteriaDto {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadList,
        this.selectedTab.id
      );
    },
    tabConf(): LoadListTab {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadTab,
        this.selectedTab.id
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      return loadListHeaders(this.actorType, false, this.tabConf.headers);
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    supplierFilterVisible(): boolean {
      return this.actorType === ActorType.Client;
    },
    customerFilterVisible(): boolean {
      return this.actorType === ActorType.Client;
    }
  },
  methods: {
    truckTypeText(load: LoadListDto): string {
      let retVal = truckTypeName(load.truckType);
      if (load.cargoType === CargoType.Container) {
        retVal += " (" + load.numContainers + ")";
      }
      return retVal;
    },
    loadStatusName: loadStatusName,
    deviationCauseText(load: LoadListDto): string | undefined {
      if (!load.deviationCause) {
        return undefined;
      }
      return deviationCauseName(load.deviationCause);
    },
    otherPriceCauseText(load: LoadListDto): string | undefined {
      if (!load.otherPriceCause) {
        return undefined;
      }
      return otherPriceCauseName(load.otherPriceCause);
    },
    fetchLoadPermissions() {
      this.$store
        .dispatch(actions.fetchPermissions, this.loadIds)
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchLoadLocks() {
      if (
        this.actorType === ActorType.Client ||
        this.actorType === ActorType.Supplier
      ) {
        this.$store.dispatch(actions.fetchLocks, this.loadIds).catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      }
    },
    search() {
      this.$emit("search");
    },
    presentNextVisibleSupplierDate(d?: MomentX): string {
      if (!!d && d.isSameOrAfter(new MomentX())) {
        return this.presentDate(d);
      }
      return "";
    },
    presentDate(d?: MomentX): string {
      return d === undefined ? "" : d.shortDateTimePrint();
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.intervalId = setInterval(() => {
        this.fetchLoadLocks();
      }, 25000);
    });
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  data: (): { intervalId?: number } => ({
    intervalId: undefined
  })
});

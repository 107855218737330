var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agreementContainer.agreementRows,"item-key":"id","loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"custom-group":_vm.customGroup,"server-items-length":_vm.agreementContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.supplierName",fn:function(){return [_c('v-row',{staticClass:"no-margin-sides"},[_c('v-col',{staticClass:"no-padding",attrs:{"cols":"4"}},[_c('CustomFilter',{attrs:{"config":{ subtype: 'supplier', cargoType: _vm.cargoTypeId },"placeholder":_vm.$t('Supplier')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.supplierId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "supplierId", $$v)},expression:"searchCriteria.supplierId"}})],1),_c('v-col',{staticClass:"no-padding small-padding-left",attrs:{"cols":"4"}},[_c('CustomFilter',{attrs:{"config":{
                  subtype: 'locationClient',
                  pickup: true,
                  cargoType: _vm.cargoTypeId
                },"placeholder":_vm.$t('PickupLocation'),"value":{
                  county: _vm.searchCriteria.pickupLocationCounty,
                  zipCode: _vm.searchCriteria.pickupLocationZipCode,
                  countryId: _vm.searchCriteria.pickupCountry
                }},on:{"input":function (val) {
                    _vm.searchCriteria.pickupLocationCounty = val.county;
                    _vm.searchCriteria.pickupLocationZipCode = val.zipCode;
                    _vm.searchCriteria.pickupCountry = val.countryId;
                  },"search":_vm.search}})],1),_c('v-col',{staticClass:"no-padding small-padding-left",attrs:{"cols":"4"}},[_c('CustomFilter',{attrs:{"config":{
                  subtype: 'locationClient',
                  pickup: false,
                  cargoType: _vm.cargoTypeId
                },"placeholder":_vm.$t('DeliveryLocation'),"value":{
                  county: _vm.searchCriteria.deliveryLocationCounty,
                  zipCode: _vm.searchCriteria.deliveryLocationZipCode,
                  countryId: _vm.searchCriteria.deliveryCountry
                }},on:{"input":function (val) {
                    _vm.searchCriteria.deliveryLocationCounty = val.county;
                    _vm.searchCriteria.deliveryLocationZipCode = val.zipCode;
                    _vm.searchCriteria.deliveryCountry = val.countryId;
                  },"search":_vm.search}})],1)],1)]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.currencyType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "currencyType", $$v)},expression:"searchCriteria.currencyType"}})]},proxy:true},{key:"filter.tag",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'tag', cargoType: _vm.cargoTypeId },"placeholder":_vm.$t('Tag')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.tag),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "tag", $$v)},expression:"searchCriteria.tag"}})]},proxy:true},{key:"filter.baseIndexName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'baseIndexDate' },"placeholder":_vm.$t('BaseIndex')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.baseIndexDate),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "baseIndexDate", $$v)},expression:"searchCriteria.baseIndexDate"}})]},proxy:true}],null,true)})]}},{key:"group.header",fn:function(ref){
                  var items = ref.items;
return [_c('td',{staticClass:"group-header",attrs:{"colspan":"2"}},[_c('span',[_vm._v(_vm._s(_vm.agreementRouteDescription(items[0])))]),(_vm.atleastAdmin)?_c('v-btn',{staticClass:"group-header-icon transparent-if-disabled",attrs:{"disabled":!_vm.editable(items[0]),"icon":"","x-small":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openRankingDialog(items[0].agreementId)}}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(!!items[0].specialRequirements)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"group-header-icon",attrs:{"color":"red","size":"18"}},on),[_vm._v(" mdi-exclamation-thick ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(items[0].specialRequirements))])]):_vm._e(),_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" . ")])],1),_c('td',{attrs:{"colspan":_vm.truckTypeNameColspan}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t(items[0].truckTypeName))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(((_vm.$t("AveragePrice")) + " (" + (_vm.$t("Top")) + " 5 / " + (_vm.$t("MobileAll")) + "):"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(items[0].averageFreigtPriceTop)+" / "+_vm._s(items[0].averageFreigtPriceAll)+" ")])])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.averagePriceTooltip)+" ")])],1),_c('td',{attrs:{"colspan":"1"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(items[0].estimatedNoOfLoads)+" ")]),_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" . ")])]),_c('td',{attrs:{"colspan":"1"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.currencyName(items[0].currency))+" ")]),_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" . ")])]),_c('td',{attrs:{"colspan":"1"}},[_c('span',[_vm._v(" "+_vm._s(items[0].tag)+" ")]),_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" . ")])]),_c('td',{attrs:{"colspan":"1"}},[_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(items[0].agreementFromDate.datePrint())+" - "+_vm._s(items[0].agreementToDate.datePrint())+" ")]),_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" . ")])]),(_vm.isRoad)?_c('td',{attrs:{"colspan":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines clickable",on:{"click":function($event){return _vm.goToIndexes(items[0])}}},on),[_vm._v(" "+_vm._s(items[0].baseIndexName)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.indexTooltip(items[0]))+" ")]),_c('div',{staticStyle:{"opacity":"0"}},[_vm._v(" . ")])],1):_vm._e()]}},{key:"item.ranking",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.ranking || "")+" ")]}},{key:"item.basePrice",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.basePrice, 0))+" ")])]}},{key:"item.unitBasePrice",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.unitBasePrice, 0))+" ")])]}},{key:"item.freightPrice",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines clickable",on:{"click":function($event){return _vm.goToIndexes(item)}}},on),[_vm._v(" "+_vm._s(_vm.money(item.freightPrice, 0))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.indexTooltip(item))+" ")])]}},{key:"item.stopPrice",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.stopPrice, 0))+" ")])]}},{key:"item.share",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.share)+"% ")]}},{key:"item.note",fn:function(ref){
                  var item = ref.item;
return [(item.note)?_c('v-tooltip',{attrs:{"top":"","open-delay":0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"icon-yellow"}},on),[_vm._v(" mdi-note ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.note))])]):_vm._e()]}},{key:"item.estimatedNoOfLoads",fn:function(){return undefined},proxy:true},{key:"item.currency",fn:function(){return undefined},proxy:true},{key:"item.tag",fn:function(){return undefined},proxy:true},{key:"item.agreementPeriod",fn:function(){return undefined},proxy:true},{key:"item.baseIndexName",fn:function(){return undefined},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }

































































































































































































































































import Vue from "vue";
import { stringCompare } from "@/scripts/misc/stringUtils";
import {
  ClientAgreementContainerDto,
  ClientAgreementRowDto,
  ClientAgreementSearchCriteria,
  CargoType,
  UserConfigurationType,
  AgreementClientOrderBy,
  UserLevel
} from "@/scripts/cld.api";
import {
  TableHeader,
  procurementEditable,
  TableOptions
} from "@/scripts/types";
import money, { percentage } from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import neverHappens from "@/scripts/misc/neverHappens";
import { gets } from "@/scripts/store/constants";
import { indexDateTooltip } from "@/components/web/loads/client/accordion/AccordionPrices.vue";
import { currencyName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter
  },
  props: {
    type: String,
    cargoTypeId: Number as () => CargoType,
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    agreementContainer: ClientAgreementContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 255);
    },
    searchCriteria(): ClientAgreementSearchCriteria {
      let type =
        this.type === this.types.agreements
          ? UserConfigurationType.ClientAgreement
          : UserConfigurationType.ClientProcurement;
      return this.$store.getters[gets.userConf](type, this.cargoTypeId);
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    truckTypeNameColspan() {
      switch (this.cargoTypeId) {
        case CargoType.Road:
          return 6;
        case CargoType.Container:
          return 5;
        case CargoType.Railroad:
          return 4;
        case CargoType.MachineTransport:
          return 6;
        default:
          return neverHappens(this.cargoTypeId);
      }
    },
    averagePriceTooltip(): string {
      return t("AveragePriceTooltip").replace(
        "{0}",
        this.cargoTypeId === CargoType.Road
          ? t("FreightPrice")
          : t("AgreedPrice")
      );
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        { value: "empty", width: 1 },
        {
          value: "supplierName",
          text: "Supplier",
          sortValue: AgreementClientOrderBy.SupplierName
        },
        {
          value: "ranking",
          text: "Ranking",
          sortValue: AgreementClientOrderBy.Ranking,
          width: 1
        }
      ];
      if (this.searchCriteria.unitBasePrice) {
        headers.push({
          value: "unitBasePrice",
          text: "UnitBasePrice",
          sortValue: AgreementClientOrderBy.Price,
          width: 1
        });
      } else {
        headers.push({
          value: "basePrice",
          text:
            this.type === this.types.agreements
              ? "AgreedPrice"
              : "OfferedPrice",
          sortValue: AgreementClientOrderBy.Price,
          width: 1
        });
      }
      switch (this.cargoTypeId) {
        case CargoType.Road:
          headers.push({
            value: "freightPrice",
            text: "FreightPrice",
            sortValue: AgreementClientOrderBy.Price,
            width: 1
          });
          headers.push({
            value: "stopPrice",
            text: "StopPrice",
            sortValue: AgreementClientOrderBy.StopPrice,
            width: 1
          });
          break;
        case CargoType.Container:
          headers.push({
            value: "serviceFee",
            text: "ServiceFee",
            sortValue: AgreementClientOrderBy.ServiceFee,
            width: 1
          });
          break;
        case CargoType.MachineTransport:
          headers.push({
            value: "initialFee",
            text: "TruckTypeFee",
            sortValue: AgreementClientOrderBy.InitialFee,
            width: 1
          });
          headers.push({
            value: "stopPrice",
            text: "StopPrice",
            sortValue: AgreementClientOrderBy.StopPrice,
            width: 1
          });
          break;
      }
      headers.push({
        value: "share",
        text: "Share",
        sortValue: AgreementClientOrderBy.Share,
        width: 1
      });
      headers.push({
        value: "note",
        text: "Note",
        sortValue: AgreementClientOrderBy.Note,
        width: 1
      });
      headers.push({
        value: "estimatedNoOfLoads",
        text: "NumberOfEstimatedLoads",
        width: 1
      });
      headers.push({
        value: "currency",
        text: "Currency",
        width: 70
      });
      headers.push({
        value: "tag",
        text: "Tag",
        width: 100
      });
      headers.push({
        value: "agreementPeriod",
        text: "AgreementPeriod",
        width: 1
      });
      if (this.cargoTypeId === CargoType.Road) {
        headers.push({
          value: "baseIndexName",
          text: "BaseIndex",
          width: 120
        });
      }
      return headers;
    },
    isRoad(): boolean {
      return this.cargoTypeId === CargoType.Road;
    },
    atleastAdmin(): boolean {
      return [UserLevel.Admin, UserLevel.SuperAdmin].includes(this.userLevel);
    },
    userLevel(): UserLevel {
      return this.$store.getters[gets.userInfo].userLevel;
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    percentage: percentage,
    search() {
      this.$emit("search");
    },
    agreementRouteDescription(item: ClientAgreementRowDto): string {
      return (
        `${item.pickupCounty}, ${item.pickupZipcode}, ${item.pickupCountryISO} - ` +
        `${item.deliveryCounty}, ${item.deliveryZipcode}, ${item.deliveryCountryISO}`
      );
    },
    openRankingDialog(agreementId: number) {
      this.$emit("openRankingDialog", agreementId);
    },
    editable(item: ClientAgreementRowDto): boolean {
      return procurementEditable(
        item.procurementFromDate,
        item.procurementToDate
      );
    },
    customGroup(
      items: ClientAgreementRowDto[]
    ): { name: string; sortVal: string; items: ClientAgreementRowDto[] }[] {
      let res: {
        name: string;
        sortVal: string;
        items: ClientAgreementRowDto[];
      }[] = [];
      for (let ar of items) {
        const matchingGroup = res.find(
          x => x.name === ar.agreementId.toString() + ar.truckTypeName
        );
        if (matchingGroup) {
          matchingGroup.items.push(ar);
        } else {
          res.push({
            name: ar.agreementId.toString() + ar.truckTypeName,
            sortVal: this.agreementRouteDescription(ar),
            items: [ar]
          });
        }
      }
      res.sort((a, b) => {
        return stringCompare(a.sortVal, b.sortVal);
      });
      return res;
    },
    indexTooltip(item: ClientAgreementRowDto): string {
      return indexDateTooltip(
        item.indexFactor,
        item.baseIndexName!,
        this.searchCriteria.date
      );
    },
    goToIndexes(item: ClientAgreementRowDto) {
      window.open(
        `/indexes?from=${item.baseIndexDate.datePrint()}&to=${this.searchCriteria.date.datePrint()}`,
        "_blank"
      );
    }
  },
  data: (): { types: { agreements: string; procurementOverview: string } } => ({
    types: {
      agreements: "agreements",
      procurementOverview: "procurement-overview"
    }
  })
});
